import "react-toastify/dist/ReactToastify.css";
import "./style/toastify.css";

import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { MotionConfig } from "framer-motion";
import React, { lazy, Suspense } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { PageLoader } from "./components/design-system/loader/PageLoader";
import { MatomoHistoryListener } from "./components/MatomoHistoryListener";
import ProtectedRoute from "./routes/ProtectedRoute";
import store, { persistor } from "./store/store";
import { healthKeyV2Theme } from "./themes/theme";

export const router = createBrowserRouter([{ path: "*", Component: Root }]);

const You = lazy(() => import("./pages/user-platform/you/You"));
const ViewPackage = lazy(
  () => import("./pages/user-platform/package/ViewPackage"),
);
const SearchResults = lazy(
  () =>
    import("./pages/user-platform/explore-care/SearchResults/SearchResults"),
);
const HealthCategory = lazy(
  () => import("./pages/user-platform/explore-care/HealthCategoryPage"),
);
const Profile = lazy(() => import("./pages/user-platform/settings/Profile"));
const Login2 = lazy(() => import("./pages/authentication/Login2"));
const Register2 = lazy(() => import("./pages/authentication/Register2"));

const Waitlist = lazy(() => import("./pages/authentication/Waitlist"));

const CreateEmployer = lazy(
  () => import("./pages/employer-portal/create-employer/CreateEmployer"),
);
const Onboard = lazy(() => import("./pages/authentication/Onboard/Onboard"));
const ManageEmailPreferences = lazy(
  () => import("./pages/ManageEmailPreferences"),
);
const SiteMap = lazy(() => import("./pages/SiteMap"));
const Invite = lazy(() => import("./pages/authentication/Invite/Invite"));

const ExpandedProviderPage2 = lazy(
  () => import("./pages/user-platform/provider-page/ExpandedProviderPage2"),
);
const ExpandedServicePage = lazy(
  () => import("./pages/user-platform/service-page/ExpandedServicePage"),
);
const AccountDeleted = lazy(
  () => import("./pages/authentication/AccountDeleted"),
);

const UserPackageSelect = lazy(
  () => import("./pages/user-platform/you/UserPackageSelect"),
);

const UserMultiplePackageSelect = lazy(
  () => import("./pages/user-platform/you/UserMultiplePackageSelect"),
);

const HealthPartnerDashboard = lazy(
  () => import("./pages/health-partner/dashboard/HealthPartnerDashboard"),
);
const HealthPartnerProfile = lazy(
  () => import("./pages/health-partner/profile/HealthPartnerProfile"),
);

const HealthPartnerEmployers = lazy(
  () => import("./pages/health-partner/employers/HealthPartnerEmployers"),
);
const EmployerInsightsAndReporting = lazy(
  () =>
    import(
      "./pages/employer-portal/insights-and-reporting/EmployerInsightsAndReporting"
    ),
);
const EmployerProviders = lazy(
  () => import("./pages/employer-portal/marketplace/EmployerProviders"),
);

const SSO = lazy(() => import("./pages/SSO"));

const EmployerManageUsers = lazy(
  () => import("./pages/employer-portal/manage-users/EmployerManageUsers"),
);

const EmployerHRISIntegration = lazy(
  () => import("./pages/employer-portal/manage-users/EmployerHRIS"),
);

const EmployerAddAndRemoveUsersCsvImport = lazy(
  () =>
    import(
      "./pages/employer-portal/manage-users/EmployerAddAndRemoveUsersCsvImport"
    ),
);
const EmployerAddAndRemoveUsersInviteLink = lazy(
  () =>
    import(
      "./pages/employer-portal/manage-users/EmployerAddAndRemoveUsersInviteLink"
    ),
);
const EmployerAddAndRemoveUsersInviteIndividualUser = lazy(
  () =>
    import(
      "./pages/employer-portal/manage-users/EmployerAddAndRemoveUsersInviteIndividualUser"
    ),
);
const EmployerSettingsAppearance = lazy(
  () =>
    import(
      "./pages/employer-portal/settings/EmployerSettingsAppearance/EmployerSettingsAppearance"
    ),
);
const EmployerSettingsBudget = lazy(
  () =>
    import(
      "./pages/employer-portal/settings/EmployerSettingsBudget/EmployerSettingsBudget"
    ),
);

const EmployerSettingsWelcomeMessage = lazy(
  () =>
    import(
      "./pages/employer-portal/settings/EmployerSettingsWelcomeMessage/EmployerSettingsWelcomeMessage"
    ),
);

const EmployerSettingsSalarySacrifice = lazy(
  () =>
    import("./pages/employer-portal/settings/EmployerSettingsSalarySacrifice"),
);

const HealthPartnerServices = lazy(
  () => import("./pages/health-partner/services/HealthPartnerServices"),
);

const EditServicePage = lazy(
  () => import("./pages/health-partner/services/EditServicePage"),
);

const EditContentPage = lazy(
  () => import("./pages/health-partner/content/EditContentPage"),
);

const EditPackagePage = lazy(
  () => import("./pages/healthkey-admin/components/EditPackagePage"),
);

const HealthKeyAdminHealthAreas = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminHealthArea"),
);

const HealthPartnerContentPage = lazy(
  () => import("./pages/health-partner/content/HealthPartnerContent"),
);
const FinishRegistration2 = lazy(
  () => import("./pages/authentication/FinishRegistration"),
);
const ForgottenPasswordEmail2 = lazy(
  () =>
    import("./pages/authentication/forgotten-password/ForgottenPasswordEmail2"),
);
const ForgottenPasswordEmailSent2 = lazy(
  () =>
    import(
      "./pages/authentication/forgotten-password/ForgottenPasswordEmailSent2"
    ),
);
const ForgottenPasswordChangePassword2 = lazy(
  () =>
    import(
      "./pages/authentication/forgotten-password/ForgottenPasswordChangePassword"
    ),
);

const AcceptInvite = lazy(() => import("./pages/authentication/AcceptInvite"));
const Wishlist = lazy(() => import("./pages/user-platform/wishlist/Wishlist"));

const DesignPreview = lazy(() => import("./pages/DesignPreview"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const OAuthConsentPage = lazy(
  () => import("./pages/authentication/OAuthConsentPage"),
);
const ServicesForYouPage = lazy(
  () => import("./pages/user-platform/explore-care/ServicesForYouPage"),
);

const RecommendationPreview = lazy(
  () =>
    import(
      "./pages/user-platform/recommendation-preview/RecommendationPreview"
    ),
);

const ContentMainPage = lazy(
  () => import("./pages/user-platform/learn/ContentMainPage"),
);
const HealthCategoryContentPage = lazy(
  () => import("./pages/user-platform/learn/HealthCategoryContentPage"),
);
const ContentForYouPage = lazy(
  () => import("./pages/user-platform/explore-care/ContentForYouPage"),
);
const ArticlePage = lazy(
  () => import("./pages/user-platform/learn/ArticlePage"),
);
const VideoPage = lazy(() => import("./pages/user-platform/learn/VideoPage"));

const AllContent = lazy(() => import("./pages/user-platform/learn/AllContent"));

const AffordableServices = lazy(
  () => import("./pages/user-platform/affordable-services/AffordableServices"),
);
const TestingActivateEmail = lazy(() => import("./pages/TestingActivateEmail"));

const HealthKeyAdminHome = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminHome"),
);
const HealthKeyAdminEmployers = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminEmployers"),
);
const HealthKeyAdminProviders = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminProviders"),
);
const HealthKeyAdminServices = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminServices"),
);
const HealthKeyAdminContent = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminContent"),
);
const HealthKeyAdminEmails = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminEmails"),
);
const HealthKeyAdminUsers = lazy(
  () => import("./pages/healthkey-admin/users/HealthKeyAdminUsers"),
);
const HealthKeyAdminPurchases = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminPurchases"),
);

const HealthKeyAdminPackages = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminPackages"),
);

const HealthKeyAdminHealthCategories = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminHealthCategories"),
);

const HealthKeyAdminEditService = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminEditService"),
);

const HealthKeyAdminEditContent = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminEditContent"),
);

const EmployerOnboardingPackageSelect = lazy(
  () =>
    import(
      "./pages/employer-portal/onboarding/EmployerOnboardingPackageSelect/EmployerOnboardingPackageSelect"
    ),
);

const EmployerPaymentCheckout = lazy(
  () => import("./pages/employer-portal/checkout/EmployerPaymentCheckout"),
);

const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));

export default function App() {
  return <RouterProvider router={router} />;
}

const HealthKeySuspense = ({ children }: { children: React.ReactNode }) => {
  return <Suspense fallback={<PageLoader />}>{children}</Suspense>;
};

const Fallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  if ((error as Error)?.name.startsWith("ChunkLoadError")) {
    window.location.reload();
    return <PageLoader />;
  }

  return <ErrorPage />;
};

const employerPortal = (): React.ReactNode[] => {
  return [
    <Route
      key="/employerPortal/:payerId/dashboard"
      path="/employerPortal/:payerId/dashboard"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerInsightsAndReporting />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/providers"
      path="/employerPortal/:payerId/providers"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerProviders />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/manage-users"
      path="/employerPortal/:payerId/manage-users"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerManageUsers />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/manage-users/add-and-remove/csv-upload"
      path="/employerPortal/:payerId/manage-users/add-and-remove/csv-upload"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerAddAndRemoveUsersCsvImport />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/manage-users/add-and-remove/invite-link"
      path="/employerPortal/:payerId/manage-users/add-and-remove/invite-link"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerAddAndRemoveUsersInviteLink />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/manage-users/add-and-remove/invite-individual-user"
      path="/employerPortal/:payerId/manage-users/add-and-remove/invite-individual-user"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerAddAndRemoveUsersInviteIndividualUser />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/employerPortal/:payerId/manage-users/add-and-remove/hr-integration"
      key="/employerPortal/:payerId/manage-users/add-and-remove/hr-integration"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerHRISIntegration />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/settings/budget"
      path="/employerPortal/:payerId/settings/budget"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerSettingsBudget />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/settings/salary-sacrifice"
      path="/employerPortal/:payerId/settings/salary-sacrifice"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerSettingsSalarySacrifice />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/settings/appearance"
      path="/employerPortal/:payerId/settings/appearance"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerSettingsAppearance />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/settings/welcome-message"
      path="/employerPortal/:payerId/settings/welcome-message"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerSettingsWelcomeMessage />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
  ];
};

const providerPortal = (): React.ReactNode[] => {
  return [
    <Route
      key="/healthPartner/employers"
      path="/healthPartner/employers"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerEmployers />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/healthPartner/dashboard"
      path="/healthPartner/dashboard"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerDashboard />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/healthPartner/dashboard"
      key="/healthPartner/dashboard"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerDashboard />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/healthPartner/profile"
      key="/healthPartner/profile"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerProfile />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/healthPartner/services"
      key="/healthPartner/services"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerServices />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/healthPartner/editService/:serviceId"
      key="/healthPartner/editService/:serviceId"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EditServicePage />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/healthPartner/content/:contentId/:providerId/:operation"
      key="/healthPartner/content/:contentId/:providerId/:operation"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EditContentPage />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/healthPartner/content"
      key="/healthPartner/content"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerContentPage />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
  ];
};

const healthKeyAdminPortal = (): React.ReactNode[] => {
  return [
    <Route
      key="/adminPortal/home"
      path="/adminPortal/home"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminHome />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/adminPortal/employers"
      path="/adminPortal/employers"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminEmployers />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/providers"
      key="/adminPortal/providers"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminProviders />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/services"
      key="/adminPortal/services"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminServices />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/editService/:serviceId"
      key="/adminPortal/editService/:serviceId"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminEditService />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/content/:contentId/:providerId/:operation"
      key="/adminPortal/content/:contentId/:providerId/:operation"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminEditContent />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/content"
      key="/adminPortal/content"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminContent />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/health-categories"
      key="/adminPortal/health-categories"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminHealthCategories />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/health-areas"
      key="/adminPortal/health-areas"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminHealthAreas />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/emails"
      key="/adminPortal/emails"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminEmails />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/users"
      key="/adminPortal/users"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminUsers />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/purchases"
      key="/adminPortal/purchases"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminPurchases />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/packages"
      key="/adminPortal/packages"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminPackages />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/editPackage/:packageId"
      key="/adminPortal/editPackage/:packageId"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EditPackagePage />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
  ];
};

export function Root() {
  return (
    <>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            fontFamily: `Karla, Roboto, "Helvetica Neue", Arial, sans-serif`,
          },
        }}
      />
      <ThemeProvider theme={healthKeyV2Theme}>
        <MotionConfig reducedMotion="user">
          <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
              <HelmetProvider>
                <ErrorBoundary
                  fallbackRender={Fallback}
                  onReset={(details) => {
                    // Reset the state of your app so the error doesn't happen again
                    console.warn(details);
                  }}
                >
                  <MatomoHistoryListener>
                    <Routes>
                      <Route
                        path="/login"
                        element={
                          <HealthKeySuspense>
                            <Login2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/register/:userEmail"
                        element={
                          <HealthKeySuspense>
                            <Register2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/invite/:employerSlug"
                        element={
                          <HealthKeySuspense>
                            <Invite />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/waitlist"
                        element={
                          <HealthKeySuspense>
                            <Waitlist />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/createEmployer"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <CreateEmployer />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/createEmployerPackage"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <EmployerOnboardingPackageSelect />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/createEmployerPackage/:error"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <EmployerOnboardingPackageSelect />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/userSelectPackage/:servicePackageId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <UserPackageSelect />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/userMultipleSelectPackage"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <UserMultiplePackageSelect />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/employerCheckout/:what/:whatId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <EmployerPaymentCheckout />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/employerCheckout/processing"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <EmployerPaymentCheckout />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/onboard/:employerId"
                        element={
                          <HealthKeySuspense>
                            <Onboard />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/onboard/:employerId/group/:groupId"
                        element={
                          <HealthKeySuspense>
                            <Onboard />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/manageEmailPreferences"
                        element={
                          <HealthKeySuspense>
                            <ManageEmailPreferences />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/unsubscribe"
                        element={
                          <HealthKeySuspense>
                            <ManageEmailPreferences />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/siteMap"
                        element={
                          <HealthKeySuspense>
                            <SiteMap />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/finishRegistration/:userId"
                        element={
                          <HealthKeySuspense>
                            <FinishRegistration2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/finishRegistration/:userId/:payerId"
                        element={
                          <HealthKeySuspense>
                            <FinishRegistration2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/finishRegistration"
                        element={
                          <HealthKeySuspense>
                            <FinishRegistration2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/forgottenPasswordEmail"
                        element={
                          <HealthKeySuspense>
                            <ForgottenPasswordEmail2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/forgottenPasswordEmail/:userEmail"
                        element={
                          <HealthKeySuspense>
                            <ForgottenPasswordEmail2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/forgottenPasswordEmailSent"
                        element={
                          <HealthKeySuspense>
                            <ForgottenPasswordEmailSent2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/forgottenPasswordChangePassword/:resetToken"
                        element={
                          <HealthKeySuspense>
                            <ForgottenPasswordChangePassword2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/acceptInvite/:userId/:resetToken/:payerId"
                        element={
                          <HealthKeySuspense>
                            <AcceptInvite />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/sso"
                        element={
                          <HealthKeySuspense>
                            <SSO />
                          </HealthKeySuspense>
                        }
                      />

                      <Route
                        path="/error"
                        element={
                          <HealthKeySuspense>
                            <ErrorPage />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/accountDeleted"
                        element={
                          <HealthKeySuspense>
                            <AccountDeleted />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/"
                        element={
                          <HealthKeySuspense>
                            <Login2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/new"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <You />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/you"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <You />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/profile"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <Profile />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/wishlist"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <Wishlist />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/profile/:section"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <Profile />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/profile/:section/:tab"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <Profile />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/affordableCare"
                        element={
                          <HealthKeySuspense>
                            <AffordableServices />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/findCare/results"
                        element={
                          <HealthKeySuspense>
                            <SearchResults />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/findCare/healthCategory/:healthCategory"
                        element={
                          <HealthKeySuspense>
                            <HealthCategory />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/findCare/forYou"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ServicesForYouPage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/recommendation-preview"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <RecommendationPreview />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/package/:packageId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ViewPackage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/content"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ContentMainPage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/content/healthCategory/:healthCategory"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <HealthCategoryContentPage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/content/forYou"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ContentForYouPage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/content/:type"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <AllContent />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/content/:type/results"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <SearchResults />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/content/results"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <SearchResults />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/article/:articleId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ArticlePage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/video/:videoId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <VideoPage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/new/paymentConfirmation/:amount"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <You />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/new/paymentConfirmation/:amount/:transactionIdToComplete"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <You />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/userSelectPackage/finish/:servicePackageId/:userPackagePaymentIntentId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <UserPackageSelect />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/userMultipleSelectPackage/finish/:servicePackageId/:userPackagePaymentIntentId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <UserMultiplePackageSelect />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/provider/:providerId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ExpandedProviderPage2 />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/service/:serviceId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ExpandedServicePage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/service/:serviceId/transactionIdToComplete/:transactionIdToComplete"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ExpandedServicePage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      {employerPortal()}
                      {providerPortal()}
                      {healthKeyAdminPortal()}
                      <Route
                        path="/oauth"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <OAuthConsentPage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/design-preview"
                        element={
                          <HealthKeySuspense>
                            <DesignPreview />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/privacy-policy"
                        element={
                          <HealthKeySuspense>
                            <PrivacyPolicy />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/terms-of-use"
                        element={
                          <HealthKeySuspense>
                            <TermsOfUse />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/testing/activateEmail/:email"
                        element={
                          <HealthKeySuspense>
                            <TestingActivateEmail />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="*"
                        element={
                          <HealthKeySuspense>
                            <PageNotFound />
                          </HealthKeySuspense>
                        }
                      />
                    </Routes>
                  </MatomoHistoryListener>
                </ErrorBoundary>
              </HelmetProvider>
            </PersistGate>
          </Provider>
        </MotionConfig>
      </ThemeProvider>
    </>
  );
}
