import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AdminPortalState = {
  excludedPayerIds: string[] | undefined;
  excludedEmailRegex: string | undefined;
};

const initialState: AdminPortalState = {
  excludedPayerIds: [],
  excludedEmailRegex: undefined,
};

const adminPortalSlice = createSlice({
  name: "adminPortal",
  initialState,
  reducers: {
    setExcludedPayerIds(
      state: AdminPortalState,
      action: PayloadAction<string[]>,
    ) {
      state.excludedPayerIds = action.payload;
    },
    setExcludedEmailRegex(
      state: AdminPortalState,
      action: PayloadAction<string>,
    ) {
      state.excludedEmailRegex = action.payload;
    },
    clearState(state: AdminPortalState) {
      state.excludedPayerIds = [];
      state.excludedEmailRegex = undefined;
    },
  },
});

export default adminPortalSlice;
