export enum Entity {
  Employer = "Employer",
  Provider = "Provider",
  ProviderToEdit = "ProviderToEdit",
  ProviderToAuthorise = "ProviderToAuthorise",
  HealthCategory = "HealthCategory",
  Service = "Service",

  ServiceOutput = "ServiceOutput",

  Wishlist = "Wishlist",
  EmployersForProvider = "EmployersForProvider",
  Wallet = "Wallet",
  EmployerForUser = "EmployerForUser",

  PayersForUser = "PayersForUser",
  Purchase = "Purchase",
  EmployerInfo = "EmployerInfo",
  EmployerInsights = "EmployerInsights",
  EmployerBudgetInfo = "EmployerBudgetInfo",
  BudgetHistory = "BudgetHistory",
  HealthCategoryPreference = "HealthCategoryPreference",
  HealthAreaPreference = "HealthAreaPreference",
  Content = "Content",
  StackOneConnectedAccount = "StackOneConnectedAccount",
  StackOneImportPreview = "StackOneImportPreview",
  ServicePackage = "ServicePackage",
  OnboardingPageContent = "OnboardingPageContent",
  FeatureBundle = "FeatureBundle",

  PayersForAdmin = "PayersForAdmin",

  UsersForAdmin = "EmployersForAdminUsersForAdmin",

  ProvidersForAdmin = "ProvidersForAdmin",
  UserData = "UserData",
  ServicesForAdmin = "ServicesForAdmin",
  ContentForAdmin = "ContentForAdmin",
  AdminsForProvider = "AdminsForProvider",
  OnboardingTask = "OnboardingTask",
  AdminsForPayer = "AdminsForPayer",

  UserSubscriptions = "UserSubscriptions",

  Notification = "Notification",
  RecommendationsForUser = "RecommendationsForUser",
  SpotlightsForUser = "SpotlightsForUser",
  HealthCategoryForAdmin = "HealthCategoryForAdmin",

  ProviderReviewRequest = "ProviderReviewRequest",
  ProviderSpotlight = "ProviderSpotlight",
  PackagesForAdmin = "PackagesForAdmin",
  HealthAreaProductOrdering = "HealthAreaProductOrdering",
  SearchMeta = "SearchMeta",
  EmailPreferences = "EmailPreferences",
  HealthCategorySynonym = "HealthCategorySynonym",
}

export const cacheTime = {
  [Entity.Employer]: 60 * 5,
  [Entity.Provider]: 60 * 5,
  [Entity.Service]: 60 * 5,
  [Entity.HealthCategory]: 60 * 5,
  [Entity.Wishlist]: 0, // zero because we don't have the mutations using Redux Query yet. -- LH, 2024-02-21
  [Entity.ProviderToEdit]: 0,
  [Entity.ProviderToAuthorise]: 0,
  [Entity.Wallet]: 0,
  [Entity.EmployerForUser]: 60 * 5,
  [Entity.Purchase]: 60 * 5,
  [Entity.EmployerInfo]: 60 * 5,
  [Entity.EmployerInsights]: 60 * 5,
  [Entity.EmployerBudgetInfo]: 60 * 5,
  [Entity.BudgetHistory]: 60 * 5,
  [Entity.HealthAreaPreference]: 60 * 5,
  [Entity.HealthCategoryPreference]: 60 * 5,
  [Entity.Content]: 60 * 5,
  [Entity.StackOneConnectedAccount]: 60 * 5,
  [Entity.StackOneImportPreview]: 0,
  [Entity.ServicePackage]: 60 * 5,
  [Entity.OnboardingPageContent]: 60 * 5,
  [Entity.PayersForAdmin]: 60 * 5,
  [Entity.ProvidersForAdmin]: 60 * 5,
  [Entity.UsersForAdmin]: 60 * 5,
  [Entity.UserData]: 60 * 5,
  [Entity.ServicesForAdmin]: 60 * 5,
  [Entity.ContentForAdmin]: 60 * 5,
  [Entity.AdminsForProvider]: 60 * 5,
  [Entity.OnboardingTask]: 60 * 5,
  [Entity.PayersForUser]: 60 * 5,
  [Entity.AdminsForPayer]: 60 * 5,
  [Entity.UserSubscriptions]: 60 * 5,
  [Entity.Notification]: 60 * 5,
  [Entity.ProviderReviewRequest]: 60 * 5,
  [Entity.PackagesForAdmin]: 60 * 5,
  [Entity.SpotlightsForUser]: 60 * 5,
  [Entity.HealthCategorySynonym]: 60 * 5,
};
