import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { HealthCategory, ProductForUser } from "../../types/types";

type ServicesState = {
  categories: Array<string>;

  allServices: Array<ProductForUser>;

  filteredServices: Array<ProductForUser>;

  allHealthCategories: Array<HealthCategory>;

  wishlist: Array<ProductForUser>;
};

const initialState: ServicesState = {
  categories: new Array<string>(),
  allServices: new Array<ProductForUser>(),
  filteredServices: new Array<ProductForUser>(),
  allHealthCategories: new Array<HealthCategory>(),
  wishlist: new Array<ProductForUser>(),
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setAllServices(
      state: ServicesState,
      action: PayloadAction<ProductForUser[]>,
    ) {
      state.allServices = action.payload;
      state.filteredServices = action.payload;
    },
    setCategories(state: ServicesState, action: PayloadAction<string[]>) {
      state.categories = action.payload;
    },
    setAllHealthCategories(
      state: ServicesState,
      action: PayloadAction<HealthCategory[]>,
    ) {
      state.allHealthCategories = action.payload;
    },
    setWishlist(state: ServicesState, action: PayloadAction<ProductForUser[]>) {
      state.wishlist = new Array<ProductForUser>();
      state.wishlist = action.payload;
    },
    setFilteredServices(
      state: ServicesState,
      action: PayloadAction<ProductForUser[]>,
    ) {
      state.filteredServices = new Array<ProductForUser>();
      state.filteredServices = action.payload;
    },
    clearCategories(state: ServicesState) {
      state.categories = new Array<string>();
    },
  },
});

export default servicesSlice;
