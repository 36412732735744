import {
  BudgetHistory,
  EmailPreferences,
  OnboardingTask,
  PayerForUser,
  ProductForUser,
  ProviderReviewRequest,
  Purchase,
  UserHealthCategoriesPreferences,
  UserInfo,
  UserNotification,
  Wallet,
} from "../../../types/types";
import { healthkeyAPI } from "../healthkeyAPI";
import { cacheTime, Entity } from "./definitions";

const usersAPI = healthkeyAPI.injectEndpoints({
  endpoints: (builder) => ({
    walletForUser: builder.query<Wallet, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.Wallet],
      query: (userId: string) => `/users/${userId}/wallet`,
      providesTags: [Entity.Wallet],
    }),
    purchasesForUser: builder.query<Purchase[], string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.Purchase],
      query: (userId: string) => `/users/${userId}/purchases`,
      providesTags: [Entity.Purchase],
    }),
    wishlistForUser: builder.query<ProductForUser[], string | undefined>({
      providesTags: [Entity.Wishlist],
      keepUnusedDataFor: cacheTime[Entity.Wishlist],
      query: (userId: string) => `/users/${userId}/wishlist`,
    }),
    budgetHistory: builder.query<BudgetHistory[], string | undefined>({
      providesTags: [Entity.BudgetHistory],
      keepUnusedDataFor: cacheTime[Entity.BudgetHistory],
      query: (userId: string) => `/users/${userId}/budgetHistory`,
    }),
    employerForUser: builder.query<PayerForUser, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.EmployerForUser],
      query: (userId: string) => ({
        url: `/users/${userId}/type/employer`,
      }),
      providesTags: [Entity.EmployerForUser],
    }),
    healthCategoriesPreference: builder.query<
      UserHealthCategoriesPreferences,
      string | undefined
    >({
      keepUnusedDataFor: cacheTime[Entity.HealthCategoryPreference],
      query: (userId: string) => `/users/${userId}/healthCategoriesPreferences`,
      providesTags: [Entity.HealthCategoryPreference],
    }),
    updateHealthCategoriesPreference: builder.mutation<
      UserHealthCategoriesPreferences,
      UserHealthCategoriesPreferences
    >({
      query: (updatePreferences: UserHealthCategoriesPreferences) => ({
        method: "PUT",
        body: {
          categoriesIds: updatePreferences.categoriesIds,
        },
        url: `/users/${updatePreferences.userId}/healthCategoriesPreferences`,
      }),
      invalidatesTags: [Entity.OnboardingTask],
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPreferences } = await queryFulfilled;
          dispatch(
            usersAPI.util.updateQueryData(
              "healthCategoriesPreference",
              userId,
              (draft) => {
                Object.assign(draft, updatedPreferences);
              },
            ),
          );
        } catch {
          //nothing to catch}
        }
      },
    }),
    healthAreasPreferences: builder.query<
      UserHealthCategoriesPreferences,
      string | undefined
    >({
      keepUnusedDataFor: cacheTime[Entity.HealthAreaPreference],
      query: (userId: string) => `/users/${userId}/healthAreasPreferences`,
      providesTags: [Entity.HealthAreaPreference],
    }),
    onboardingTasks: builder.query<OnboardingTask[], { userId: string }>({
      query: ({ userId }) => `/users/${userId}/onboardingTasks`,
      providesTags: [Entity.OnboardingTask],
    }),
    notifications: builder.query<UserNotification[], { userId: string }>({
      query: ({ userId }) => `/users/${userId}/notifications`,
      providesTags: [Entity.Notification],
      keepUnusedDataFor: cacheTime[Entity.Notification],
    }),
    providerReviewRequests: builder.query<
      ProviderReviewRequest[],
      { userId: string }
    >({
      query: ({ userId }) => `/users/${userId}/providerReviewRequests`,
      providesTags: [Entity.ProviderReviewRequest],
      keepUnusedDataFor: cacheTime[Entity.ProviderReviewRequest],
    }),
    markProviderReviewRequestAsSeen: builder.mutation<
      ProviderReviewRequest[],
      { userId: string; requestId: string }
    >({
      query: ({ userId, requestId }) => ({
        method: "PATCH",
        url: `/users/${userId}/providerReviewRequests/${requestId}`,
      }),
      invalidatesTags: [Entity.ProviderReviewRequest],
    }),
    emailPreferences: builder.query<EmailPreferences, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.EmailPreferences],
      query: (userEmail: string) => `/users/${userEmail}/emailPreferences`,
      providesTags: [Entity.EmailPreferences],
    }),
    updateHealthAreasPreferences: builder.mutation<
      UserHealthCategoriesPreferences,
      UserHealthCategoriesPreferences
    >({
      query: (updatePreferences: UserHealthCategoriesPreferences) => ({
        method: "PUT",
        body: {
          categoriesIds: updatePreferences.categoriesIds,
        },
        url: `/users/${updatePreferences.userId}/healthAreasPreferences`,
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPreferences } = await queryFulfilled;
          dispatch(
            usersAPI.util.updateQueryData(
              "healthAreasPreferences",
              userId,
              (draft) => {
                Object.assign(draft, updatedPreferences);
              },
            ),
          );
        } catch {
          //nothing to catch}
        }
      },
    }),
    updateUserDetails: builder.mutation<
      void,
      { userId: string; userInfo: UserInfo }
    >({
      invalidatesTags: [Entity.EmployerInfo],
      query: ({ userId, userInfo }) => ({
        method: "PUT",
        url: `/users/${userId}`,
        body: userInfo,
      }),
    }),
    updateUserEmailPreferences: builder.mutation<
      void,
      { userEmail: string | undefined; emailPreferences: EmailPreferences }
    >({
      query: ({ userEmail, emailPreferences }) => ({
        method: "POST",
        url: `/users/${userEmail}/emailPreferences`,
        body: emailPreferences,
      }),
      invalidatesTags: [Entity.EmailPreferences],
    }),
    addServiceToWishlist: builder.mutation<
      void,
      {
        userId: string;
        serviceId: string;
      }
    >({
      invalidatesTags: [Entity.Wishlist],
      query: ({ userId, serviceId }) => ({
        method: "POST",
        url: `/users/${userId}/wishlist/${serviceId}`,
      }),
    }),
    removeServiceFromWishlist: builder.mutation<
      void,
      {
        userId: string;
        serviceId: string;
      }
    >({
      invalidatesTags: [Entity.Wishlist],
      query: ({ userId, serviceId }) => ({
        method: "DELETE",
        url: `/users/${userId}/wishlist/${serviceId}`,
      }),
    }),
    updateOnboardingTasks: builder.mutation<
      void,
      { onboardingTask: OnboardingTask; userId: string }
    >({
      query: ({ onboardingTask, userId }) => ({
        url: `/users/${userId}/onboardingTasks`,
        method: "PUT",
        body: onboardingTask,
      }),

      invalidatesTags: [Entity.OnboardingTask],
    }),
  }),
});

export const {
  useWishlistForUserQuery,
  useEmployerForUserQuery,
  useWalletForUserQuery,
  usePurchasesForUserQuery,
  useAddServiceToWishlistMutation,
  useRemoveServiceFromWishlistMutation,
  useBudgetHistoryQuery,
  useUpdateUserDetailsMutation,
  useHealthCategoriesPreferenceQuery,
  useOnboardingTasksQuery,
  useUpdateHealthCategoriesPreferenceMutation,
  useHealthAreasPreferencesQuery,
  useUpdateHealthAreasPreferencesMutation,
  useUpdateOnboardingTasksMutation,
  useNotificationsQuery,
  useProviderReviewRequestsQuery,
  useMarkProviderReviewRequestAsSeenMutation,
  useUpdateUserEmailPreferencesMutation,
  useEmailPreferencesQuery,
} = usersAPI;
